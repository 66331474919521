import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"
import ContactForm from "../components/contact-form"
import Testimonials from "../components/testimonials"

class ContactPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true }>
        <SEO title="Contact Us" />
        <Introduction 
          title="Contact us"
        />
        <div className="row shrink-width">
          <div className="column small-12 medium-5 large-4">
            <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
            <p><a target="_blank" rel="noopener" href="mailto:steve@old-salt.co" className="underline">Email Us</a></p><p className="gm-bottom--2"><a href="tel:07905899611" className="underline">07905 899611</a></p>
          </div>
          <div className="column small-12 medium-7 large-6 large-offset-1">
            <ContactForm />
          </div>
        </div>

        <Spacer space="4" />

        <Testimonials />

        <Spacer space="4" />
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
            <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`